import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>

      <h2>SEARCH ENGINE OPTIMIZATION (SE0) / SEM CONSULTANT</h2><hr/>
    <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>At least 1-3 years of experience in the same or similar roles</li>
          <li>Proven experience in site optimization</li>
          <li>A degree in marketing is an advantage</li>
          <li>Experience in search engine algorithms and website architecture from SEO perspective and with the ability to provide top notch recommendations</li>
          <li>Solid analytical skills and key performance metrics is a must with an understanding of Google Analytics and similar tools</li>
          <li>SEO copywriting best practices knowledge for an on-page and off-page copy</li>
          <li>Up-to-date with the latest trends and practices in SEO and SEM</li>
          <li>Knowledge in Digital Marketing</li>
          <li>Strong attention to detail</li>
          <li>Ability to work in a fast-paced environment</li>
          <li>Good communication skills</li>
          <li>Good research skills</li>
          <li>Able to work with minimal supervision</li>
        </ul>
      </Modal.Body>
      <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
